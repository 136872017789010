/* GRID */

.w-grid,
.w-grid-full,
.w-grid-wide {
    display: block;
    position: relative;
    box-sizing: border-box;
    overflow: auto;
    max-width: 984px;
    margin: 0 auto;
    overflow-x: hidden;
}

.w-grid-full {
    max-width: 100%;
}

.w-grid-wide {
    max-width: 1366px;
}

/* #region columns */
.g1, .g2, .g3, .g4, .g5, .g6, .g7, .g8, .g9, .g10,
.g11, .g12, .g13, .g14, .g15, .g16, .g17, .g18, .g19, .g20,
.g-half, .g-third, .g-all {
    display: block;
    /* floating so that whitespace doesn't cause spacing between columns other
    than gutters */
    float: left;
    /* border sizing to be sure the width is set to the whole rendered object */
    box-sizing: border-box;
    /* this could do problems in some cases, so override as needed */
    overflow: hidden;
}
/* remove gutter margin from last elements on row or in wrapper */
.g20, .g-all, .g-last, .g1 .g1, .g2 .g2, .g3 .g3, .g4 .g4, .g5 .g5, .g6 .g6,
.g7 .g7, .g8 .g8, .g9 .g9, .g10 .g10, .g11 .g11, .g12 .g12, .g13 .g13, .g14 .g14,
.g15 .g15, .g16 .g16, .g17 .g17, .g18 .g18, .g19 .g19 {
    width: 100%;
    margin-right: 0 !important;
}

.g-all {
    float: none;
}

.g-center {
    float: none;
    margin-left: auto !important;
    margin-right: auto !important;
}

/* highlight or hide impossible nested columns combinations */
.g1 .g2, .g1 .g3, .g1 .g4, .g1 .g5, .g1 .g6, .g1 .g7, .g1 .g8, .g1 .g9, .g1 .g10,
.g1 .g11, .g1 .g12, .g1 .g13, .g1 .g14, .g1 .g15, .g1 .g16, .g1 .g17, .g1 .g18,
.g1 .g19, .g1 .g20,
.g2 .g3, .g2 .g4, .g2 .g5, .g2 .g6, .g2 .g7, .g2 .g8, .g2 .g9,
.g2 .g10, .g2 .g11, .g2 .g12, .g2 .g13, .g2 .g14, .g2 .g15, .g2 .g16, .g2 .g17,
.g2 .g18, .g2 .g19, .g2 .g20, .g3 .g4, .g3 .g5, .g3 .g6, .g3 .g7, .g3 .g8, .g3 .g9,
.g3 .g10, .g3 .g11, .g3 .g12, .g3 .g13, .g3 .g14, .g3 .g15, .g3 .g16, .g3 .g17,
.g3 .g18, .g3 .g19, .g3 .g20,
.g4 .g5, .g4 .g6, .g4 .g7, .g4 .g8, .g4 .g9, .g4 .g10,
.g4 .g11, .g4 .g12, .g4 .g13, .g4 .g14, .g4 .g15, .g4 .g16, .g4 .g17, .g4 .g18,
.g4 .g19, .g4 .g20,
.g5 .g6, .g5 .g7, .g5 .g8, .g5 .g9, .g5 .g10, .g5 .g11, .g5 .g12,
.g5 .g13, .g5 .g14, .g5 .g15, .g5 .g16, .g5 .g17, .g5 .g18, .g5 .g19, .g5 .g20,
.g6 .g7, .g6 .g8, .g6 .g9, .g6 .g10, .g6 .g11, .g6 .g12, .g6 .g13, .g6 .g14, .g6 .g15, .g6 .g16, .g6 .g17, .g6 .g18, .g6 .g19, .g6 .g20,
.g7 .g8, .g7 .g9, .g7 .g10, .g7 .g11, .g7 .g12, .g7 .g13, .g7 .g14, .g7 .g15, .g7 .g16, .g7 .g17, .g7 .g18, .g7 .g19, .g7 .g20,
.g8 .g9, .g8 .g10, .g8 .g11, .g8 .g12, .g8 .g13, .g8 .g14, .g8 .g15, .g8 .g16, .g8 .g17, .g8 .g18, .g8 .g19, .g8 .g20,
.g9 .g10, .g9 .g11, .g9 .g12, .g9 .g13, .g9 .g14, .g9 .g15, .g9 .g16, .g9 .g17, .g9 .g18, .g9 .g19, .g9 .g20,
.g10 .g11, .g10 .g12, .g10 .g13, .g10 .g14, .g10 .g15, .g10 .g16, .g10 .g17, .g10 .g18, .g10 .g19, .g10 .g20,
.g11 .g12, .g11 .g13, .g11 .g14, .g11 .g15, .g11 .g16, .g11 .g17, .g11 .g18, .g11 .g19, .g11 .g20,
.g12 .g13, .g12 .g14, .g12 .g15, .g12 .g16, .g12 .g17, .g12 .g18, .g12 .g19, .g12 .g20,
.g13 .g14, .g13 .g15, .g13 .g16, .g13 .g17, .g13 .g18, .g13 .g19, .g13 .g20,
.g14 .g15, .g14 .g16, .g14 .g17, .g14 .g18, .g14 .g19, .g14 .g20,
.g15 .g16, .g15 .g17, .g15 .g18, .g15 .g19, .g15 .g20,
.g16 .g17, .g16 .g18, .g16 .g19, .g16 .g20,
.g17 .g18, .g17 .g19, .g17 .g20,
.g18 .g19, .g18 .g20,
.g19 .g20 {
    background: red !important;
}
/* #endregion columns */

/* BP1 */
@media screen and (max-width: 479px) {

    /*
    ===============================================================================
    GRID DEFINITION
    base width 320px
    10 columns * 21 px + 9 gutters * 10 px + 2 margins * 10 px = 320px
    ===============================================================================
    */

    /* grid wrapper */
    .w-grid {
        width: 93.75%;
    }
    /* columns - 1st level */
    .g1, .g2, .g3, .g4, .g5, .g6, .g7, .g8, .g9 {
        margin-right: 3.33333333333333%;
    }

    .g1 {
        width: 7%;
    }

    .g2 {
        width: 17.3333333333333%;
    }

    .g3 {
        width: 27.6666666666667%;
    }

    .g4 {
        width: 38%;
    }

    .g5 {
        width: 48.3333333333333%;
    }

    .g6 {
        width: 58.6666666666667%;
    }

    .g7 {
        width: 69%;
    }

    .g8 {
        width: 79.3333333333333%;
    }

    .g9 {
        width: 89.6666666666667%;
    }

    /* columns - 2nd level */
    .g2 > .g1 {
        margin-right: 19.2307692307692%;
    }

    .g2 > .g1 {
        width: 40.3846153846154%;
    }

    .g3 > .g1, .g3 > .g2 {
        margin-right: 12.0481927710843%;
    }

    .g3 > .g1 {
        width: 25.3012048192771%;
    }

    .g3 > .g2 {
        width: 62.6506024096386%;
    }

    .g4 > .g1, .g4 > .g2, .g4 > .g3 {
        margin-right: 8.7719298245614%;
    }

    .g4 > .g1 {
        width: 18.4210526315789%;
    }

    .g4 > .g2 {
        width: 45.6140350877193%;
    }

    .g4 > .g3 {
        width: 72.8070175438597%;
    }

    .g5 > .g1, .g5 > .g2, .g5 > .g3, .g5 > .g4 {
        margin-right: 6.89655172413793%;
    }

    .g5 > .g1 {
        width: 14.4827586206897%;
    }

    .g5 > .g2 {
        width: 35.8620689655172%;
    }

    .g5 > .g3 {
        width: 57.2413793103448%;
    }

    .g5 > .g4 {
        width: 78.6206896551724%;
    }

    .g6 > .g1, .g6 > .g2, .g6 > .g3, .g6 > .g4, .g6 > .g5 {
        margin-right: 5.68181818181818%;
    }

    .g6 > .g1 {
        width: 11.9318181818182%;
    }

    .g6 > .g2 {
        width: 29.5454545454545%;
    }

    .g6 > .g3 {
        width: 47.1590909090909%;
    }

    .g6 > .g4 {
        width: 64.7727272727273%;
    }

    .g6 > .g5 {
        width: 82.3863636363636%;
    }

    .g7 > .g1, .g7 > .g2, .g7 > .g3, .g7 > .g4, .g7 > .g5, .g7 > .g6 {
        margin-right: 4.83091787439614%;
    }

    .g7 > .g1 {
        width: 10.1449275362319%;
    }

    .g7 > .g2 {
        width: 25.1207729468599%;
    }

    .g7 > .g3 {
        width: 40.0966183574879%;
    }

    .g7 > .g4 {
        width: 55.0724637681159%;
    }

    .g7 > .g5 {
        width: 70.048309178744%;
    }

    .g7 > .g6 {
        width: 85.024154589372%;
    }

    .g8 > .g1, .g8 > .g2, .g8 > .g3, .g8 > .g4, .g8 > .g5, .g8 > .g6, .g8 > .g7 {
        margin-right: 4.20168067226891%;
    }

    .g8 > .g1 {
        width: 8.82352941176471%;
    }

    .g8 > .g2 {
        width: 21.8487394957983%;
    }

    .g8 > .g3 {
        width: 34.8739495798319%;
    }

    .g8 > .g4 {
        width: 47.8991596638655%;
    }

    .g8 > .g5 {
        width: 60.9243697478992%;
    }

    .g8 > .g6 {
        width: 73.9495798319328%;
    }

    .g8 > .g7 {
        width: 86.9747899159664%;
    }

    .g9 > .g1, .g9 > .g2, .g9 > .g3, .g9 > .g4, .g9 > .g5, .g9 > .g6, .g9 > .g7, .g9 > .g8 {
        margin-right: 3.71747211895911%;
    }

    .g9 > .g1 {
        width: 7.80669144981413%;
    }

    .g9 > .g2 {
        width: 19.3308550185874%;
    }

    .g9 > .g3 {
        width: 30.8550185873606%;
    }

    .g9 > .g4 {
        width: 42.3791821561338%;
    }

    .g9 > .g5 {
        width: 53.9033457249071%;
    }

    .g9 > .g6 {
        width: 65.4275092936803%;
    }

    .g9 > .g7 {
        width: 76.9516728624535%;
    }

    .g9 > .g8 {
        width: 88.4758364312268%;
    }

    .g10 > .g1, .g10 > .g2, .g10 > .g3, .g10 > .g4, .g10 > .g5, .g10 > .g6, .g10 > .g7, .g10 > .g8, .g10 > .g9 {
        margin-right: 3.33333333333333%;
    }

    .g10 > .g1 {
        width: 7%;
    }

    .g10 > .g2 {
        width: 17.3333333333333%;
    }

    .g10 > .g3 {
        width: 27.6666666666667%;
    }

    .g10 > .g4 {
        width: 38%;
    }

    .g10 > .g5 {
        width: 48.3333333333333%;
    }

    .g10 > .g6 {
        width: 58.6666666666667%;
    }

    .g10 > .g7 {
        width: 69%;
    }

    .g10 > .g8 {
        width: 79.3333333333333%;
    }

    .g10 > .g9 {
        width: 89.6666666666667%;
    }

    /* columns - halves definition */
    .g-half {
        width: 48.333%;
        margin-right: 3.333%;
    }

    .g9 .g-half {
        width: 48.141%;
        margin-right: 3.717%;
    }

    .g8 .g-half {
        width: 47.899%;
        margin-right: 4.201%;
    }

    .g7 .g-half {
        width: 47.584%;
        margin-right: 4.831%;
    }

    .g6 .g-half {
        width: 47.159%;
        margin-right: 5.682%;
    }

    .g5 .g-half {
        width: 46.552%;
        margin-right: 6.896%;
    }

    .g4 .g-half {
        width: 45.614%;
        margin-right: 8.772%;
    }

    .g3 .g-half {
        width: 43.976%;
        margin-right: 12.048%;
    }

    .g2 .g-half {
        width: 40.383%;
        margin-right: 19.234%;
    }

    .g1 .g-half {
        width: 26.185%;
        margin-right: 47.629%;
    }

    /* columns - thirds definition */
    .g-third {
        width: 31.111%;
        margin-right: 3.333%;
    }

    .g9 .g-third {
        width: 30.855%;
        margin-right: 3.717%;
    }

    .g8 .g-third {
        width: 30.532%;
        margin-right: 4.201%;
    }

    .g7 .g-third {
        width: 30.112%;
        margin-right: 4.831%;
    }

    .g6 .g-third {
        width: 29.545%;
        margin-right: 5.682%;
    }

    .g5 .g-third {
        width: 28.736%;
        margin-right: 6.896%;
    }

    .g4 .g-third {
        width: 27.485%;
        margin-right: 8.772%;
    }

    .g3 .g-third {
        width: 25.301%;
        margin-right: 12.048%;
    }

    .g2 .g-third {
        width: 20.510%;
        margin-right: 19.234%;
    }

    .g1 .g-third {
        width: 1.580%;
        margin-right: 47.629%;
    }
}

/* BP2 */
@media screen and (min-width: 480px) and (max-width: 767px) {
     /*
    ===============================================================================
    GRID DEFINITION
    base width 320px
    15 columns * 22px + 14 gutters * 9px + 2 margins * 12px = 480px
    ===============================================================================
    */
    /* grid wrapper */
    .w-grid {
        width: 95%;
    }
    /* columns - 1st level */
    .g1, .g2, .g3, .g4, .g5, .g6, .g7, .g8, .g9, .g10, .g11, .g12, .g13, .g14, .g15 {
        margin-right: 1.97368421052632%;
    }

    .g1 {
        width: 4.82456140350877%;
    }

    .g2 {
        width: 11.6228070175439%;
    }

    .g3 {
        width: 18.4210526315789%;
    }

    .g4 {
        width: 25.219298245614%;
    }

    .g5 {
        width: 32.0175438596491%;
    }

    .g6 {
        width: 38.8157894736842%;
    }

    .g7 {
        width: 45.6140350877193%;
    }

    .g8 {
        width: 52.4122807017544%;
    }

    .g9 {
        width: 59.2105263157895%;
    }

    .g10 {
        width: 66.0087719298246%;
    }

    .g11 {
        width: 72.8070175438597%;
    }

    .g12 {
        width: 79.6052631578947%;
    }

    .g13 {
        width: 86.4035087719298%;
    }

    .g14 {
        width: 93.2017543859649%;
    }

    /* columns - 2nd level */
    .g2 > .g1 {
        margin-right: 16.9811320754717%;
    }

    .g2 > .g1 {
        width: 41.5094339622642%;
    }

    .g3 > .g1, .g3 > .g2 {
        margin-right: 10.7142857142857%;
    }

    .g3 > .g1 {
        width: 26.1904761904762%;
    }

    .g3 > .g2 {
        width: 63.0952380952381%;
    }

    .g4 > .g1, .g4 > .g2, .g4 > .g3 {
        margin-right: 7.82608695652174%;
    }

    .g4 > .g1 {
        width: 19.1304347826087%;
    }

    .g4 > .g2 {
        width: 46.0869565217391%;
    }

    .g4 > .g3 {
        width: 73.0434782608696%;
    }

    .g5 > .g1, .g5 > .g2, .g5 > .g3, .g5 > .g4 {
        margin-right: 6.16438356164384%;
    }

    .g5 > .g1 {
        width: 15.0684931506849%;
    }

    .g5 > .g2 {
        width: 36.3013698630137%;
    }

    .g5 > .g3 {
        width: 57.5342465753425%;
    }

    .g5 > .g4 {
        width: 78.7671232876712%;
    }

    .g6 > .g1, .g6 > .g2, .g6 > .g3, .g6 > .g4, .g6 > .g5 {
        margin-right: 5.08474576271187%;
    }

    .g6 > .g1 {
        width: 12.4293785310734%;
    }

    .g6 > .g2 {
        width: 29.9435028248588%;
    }

    .g6 > .g3 {
        width: 47.4576271186441%;
    }

    .g6 > .g4 {
        width: 64.9717514124294%;
    }

    .g6 > .g5 {
        width: 82.4858757062147%;
    }

    .g7 > .g1, .g7 > .g2, .g7 > .g3, .g7 > .g4, .g7 > .g5, .g7 > .g6 {
        margin-right: 4.32692307692308%;
    }

    .g7 > .g1 {
        width: 10.5769230769231%;
    }

    .g7 > .g2 {
        width: 25.4807692307692%;
    }

    .g7 > .g3 {
        width: 40.3846153846154%;
    }

    .g7 > .g4 {
        width: 55.2884615384615%;
    }

    .g7 > .g5 {
        width: 70.1923076923077%;
    }

    .g7 > .g6 {
        width: 85.0961538461538%;
    }

    .g8 > .g1, .g8 > .g2, .g8 > .g3, .g8 > .g4, .g8 > .g5, .g8 > .g6, .g8 > .g7 {
        margin-right: 3.76569037656904%;
    }

    .g8 > .g1 {
        width: 9.20502092050209%;
    }

    .g8 > .g2 {
        width: 22.1757322175732%;
    }

    .g8 > .g3 {
        width: 35.1464435146443%;
    }

    .g8 > .g4 {
        width: 48.1171548117155%;
    }

    .g8 > .g5 {
        width: 61.0878661087866%;
    }

    .g8 > .g6 {
        width: 74.0585774058577%;
    }

    .g8 > .g7 {
        width: 87.0292887029289%;
    }

    .g9 > .g1, .g9 > .g2, .g9 > .g3, .g9 > .g4, .g9 > .g5, .g9 > .g6, .g9 > .g7, .g9 > .g8 {
        margin-right: 3.33333333333333%;
    }

    .g9 > .g1 {
        width: 8.14814814814815%;
    }

    .g9 > .g2 {
        width: 19.6296296296296%;
    }

    .g9 > .g3 {
        width: 31.1111111111111%;
    }

    .g9 > .g4 {
        width: 42.5925925925926%;
    }

    .g9 > .g5 {
        width: 54.0740740740741%;
    }

    .g9 > .g6 {
        width: 65.5555555555556%;
    }

    .g9 > .g7 {
        width: 77.037037037037%;
    }

    .g9 > .g8 {
        width: 88.5185185185185%;
    }

    .g10 > .g1, .g10 > .g2, .g10 > .g3, .g10 > .g4, .g10 > .g5, .g10 > .g6, .g10 > .g7,
    .g10 > .g8, .g10 > .g9 {
        margin-right: 2.99003322259136%;
    }

    .g10 > .g1 {
        width: 7.30897009966777%;
    }

    .g10 > .g2 {
        width: 17.6079734219269%;
    }

    .g10 > .g3 {
        width: 27.906976744186%;
    }

    .g10 > .g4 {
        width: 38.2059800664452%;
    }

    .g10 > .g5 {
        width: 48.5049833887043%;
    }

    .g10 > .g6 {
        width: 58.8039867109634%;
    }

    .g10 > .g7 {
        width: 69.1029900332226%;
    }

    .g10 > .g8 {
        width: 79.4019933554817%;
    }

    .g10 > .g9 {
        width: 89.7009966777409%;
    }

    .g11 > .g1, .g11 > .g2, .g11 > .g3, .g11 > .g4, .g11 > .g5, .g11 > .g6, .g11 > .g7,
    .g11 > .g8, .g11 > .g9, .g11 > .g10 {
        margin-right: 2.71084337349398%;
    }

    .g11 > .g1 {
        width: 6.62650602409639%;
    }

    .g11 > .g2 {
        width: 15.9638554216867%;
    }

    .g11 > .g3 {
        width: 25.3012048192771%;
    }

    .g11 > .g4 {
        width: 34.6385542168675%;
    }

    .g11 > .g5 {
        width: 43.9759036144578%;
    }

    .g11 > .g6 {
        width: 53.3132530120482%;
    }

    .g11 > .g7 {
        width: 62.6506024096386%;
    }

    .g11 > .g8 {
        width: 71.9879518072289%;
    }

    .g11 > .g9 {
        width: 81.3253012048193%;
    }

    .g11 > .g10 {
        width: 90.6626506024096%;
    }

    .g12 > .g1, .g12 > .g2, .g12 > .g3, .g12 > .g4, .g12 > .g5, .g12 > .g6, .g12 > .g7,
    .g12 > .g8, .g12 > .g9, .g12 > .g10, .g12 > .g11 {
        margin-right: 2.47933884297521%;
    }

    .g12 > .g1 {
        width: 6.06060606060606%;
    }

    .g12 > .g2 {
        width: 14.6005509641873%;
    }

    .g12 > .g3 {
        width: 23.1404958677686%;
    }

    .g12 > .g4 {
        width: 31.6804407713499%;
    }

    .g12 > .g5 {
        width: 40.2203856749311%;
    }

    .g12 > .g6 {
        width: 48.7603305785124%;
    }

    .g12 > .g7 {
        width: 57.3002754820937%;
    }

    .g12 > .g8 {
        width: 65.8402203856749%;
    }

    .g12 > .g9 {
        width: 74.3801652892562%;
    }

    .g12 > .g10 {
        width: 82.9201101928375%;
    }

    .g12 > .g11 {
        width: 91.4600550964187%;
    }

    .g13 > .g1, .g13 > .g2, .g13 > .g3, .g13 > .g4, .g13 > .g5, .g13 > .g6, .g13 > .g7,
    .g13 > .g8, .g13 > .g9, .g13 > .g10, .g13 > .g11, .g13 > .g12 {
        margin-right: 2.28426395939086%;
    }

    .g13 > .g1 {
        width: 5.58375634517767%;
    }

    .g13 > .g2 {
        width: 13.4517766497462%;
    }

    .g13 > .g3 {
        width: 21.3197969543147%;
    }

    .g13 > .g4 {
        width: 29.1878172588832%;
    }

    .g13 > .g5 {
        width: 37.0558375634518%;
    }

    .g13 > .g6 {
        width: 44.9238578680203%;
    }

    .g13 > .g7 {
        width: 52.7918781725888%;
    }

    .g13 > .g8 {
        width: 60.6598984771574%;
    }

    .g13 > .g9 {
        width: 68.5279187817259%;
    }

    .g13 > .g10 {
        width: 76.3959390862944%;
    }

    .g13 > .g11 {
        width: 84.2639593908629%;
    }

    .g13 > .g12 {
        width: 92.1319796954315%;
    }

    .g14 > .g1, .g14 > .g2, .g14 > .g3, .g14 > .g4, .g14 > .g5, .g14 > .g6, .g14 > .g7,
    .g14 > .g8, .g14 > .g9, .g14 > .g10, .g14 > .g11, .g14 > .g12, .g14 > .g13 {
        margin-right: 2.11764705882353%;
    }

    .g14 > .g1 {
        width: 5.17647058823529%;
    }

    .g14 > .g2 {
        width: 12.4705882352941%;
    }

    .g14 > .g3 {
        width: 19.7647058823529%;
    }

    .g14 > .g4 {
        width: 27.0588235294118%;
    }

    .g14 > .g5 {
        width: 34.3529411764706%;
    }

    .g14 > .g6 {
        width: 41.6470588235294%;
    }

    .g14 > .g7 {
        width: 48.9411764705882%;
    }

    .g14 > .g8 {
        width: 56.2352941176471%;
    }

    .g14 > .g9 {
        width: 63.5294117647059%;
    }

    .g14 > .g10 {
        width: 70.8235294117647%;
    }

    .g14 > .g11 {
        width: 78.1176470588235%;
    }

    .g14 > .g12 {
        width: 85.4117647058823%;
    }

    .g14 > .g13 {
        width: 92.7058823529412%;
    }

    .g15 > .g1, .g15 > .g2, .g15 > .g3, .g15 > .g4, .g15 > .g5, .g15 > .g6, .g15 > .g7,
    .g15 > .g8, .g15 > .g9, .g15 > .g10, .g15 > .g11, .g15 > .g12, .g15 > .g13, .g15 > .g14 {
        margin-right: 1.97368421052632%;
    }

    .g15 > .g1 {
        width: 4.82456140350877%;
    }

    .g15 > .g2 {
        width: 11.6228070175439%;
    }

    .g15 > .g3 {
        width: 18.4210526315789%;
    }

    .g15 > .g4 {
        width: 25.219298245614%;
    }

    .g15 > .g5 {
        width: 32.0175438596491%;
    }

    .g15 > .g6 {
        width: 38.8157894736842%;
    }

    .g15 > .g7 {
        width: 45.6140350877193%;
    }

    .g15 > .g8 {
        width: 52.4122807017544%;
    }

    .g15 > .g9 {
        width: 59.2105263157895%;
    }

    .g15 > .g10 {
        width: 66.0087719298246%;
    }

    .g15 > .g11 {
        width: 72.8070175438597%;
    }

    .g15 > .g12 {
        width: 79.6052631578947%;
    }

    .g15 > .g13 {
        width: 86.4035087719298%;
    }

    .g15 > .g14 {
        width: 93.2017543859649%;
    }

    /* columns - halves definition */
    .g-half {
        width: 49.013%;
        margin-right: 1.973%;
    }

    .g14 .g-half {
        width: 48.941%;
        margin-right: 2.117%;
    }

    .g13 .g-half {
        width: 48.858%;
        margin-right: 2.284%;
    }

    .g12 .g-half {
        width: 48.760%;
        margin-right: 2.479%;
    }

    .g11 .g-half {
        width: 48.644%;
        margin-right: 2.711%;
    }

    .g10 .g-half {
        width: 48.505%;
        margin-right: 2.990%;
    }

    .g9 .g-half {
        width: 48.333%;
        margin-right: 3.333%;
    }

    .g8 .g-half {
        width: 48.117%;
        margin-right: 3.765%;
    }

    .g7 .g-half {
        width: 47.836%;
        margin-right: 4.327%;
    }

    .g6 .g-half {
        width: 47.458%;
        margin-right: 5.084%;
    }

    .g5 .g-half {
        width: 46.918%;
        margin-right: 6.164%;
    }

    .g4 .g-half {
        width: 46.087%;
        margin-right: 7.826%;
    }

    .g3 .g-half {
        width: 44.643%;
        margin-right: 10.714%;
    }

    .g2 .g-half {
        width: 41.509%;
        margin-right: 16.981%;
    }

    .g1 .g-half {
        width: 29.545%;
        margin-right: 40.910%;
    }

    /* columns - thirds definition */
    .g-third {
        width: 32.018%;
        margin-right: 1.973%;
    }

    .g14 .g-third {
        width: 31.922%;
        margin-right: 2.117%;
    }

    .g13 .g-third {
        width: 31.810%;
        margin-right: 2.284%;
    }

    .g12 .g-third {
        width: 31.680%;
        margin-right: 2.479%;
    }

    .g11 .g-third {
        width: 31.526%;
        margin-right: 2.711%;
    }

    .g10 .g-third {
        width: 31.340%;
        margin-right: 2.990%;
    }

    .g9 .g-third {
        width: 31.111%;
        margin-right: 3.333%;
    }

    .g8 .g-third {
        width: 30.823%;
        margin-right: 3.765%;
    }

    .g7 .g-third {
        width: 30.448%;
        margin-right: 4.327%;
    }

    .g6 .g-third {
        width: 29.944%;
        margin-right: 5.084%;
    }

    .g5 .g-third {
        width: 29.224%;
        margin-right: 6.164%;
    }

    .g4 .g-third {
        width: 28.116%;
        margin-right: 7.826%;
    }

    .g3 .g-third {
        width: 26.190%;
        margin-right: 10.714%;
    }

    .g2 .g-third {
        width: 22.012%;
        margin-right: 16.981%;
    }

    .g1 .g-third {
        width: 6.0600%;
        margin-right: 40.910%;
    }
}

/* BP4 */
@media screen and (min-width: 768px) {
    /*
    ===============================================================================
    GRID DEFINITION
    base width 1024px
    20 columns * 34px + 19 gutters * 16px + 2 margins * 20px = 1024px
    ===============================================================================
    */
    /* grid wrapper */
    .w-grid {
        width: 96.09375%;
    }
    /* columns - 1st level */
    .g1, .g2, .g3, .g4, .g5, .g6, .g7, .g8, .g9, .g10, .g11, .g12, .g13, .g14,
    .g15, .g16, .g17, .g18, .g19 {
        margin-right: 1.6260162601626%;
    }

    .g1 {
        width: 3.45528455284553%;
    }

    .g2 {
        width: 8.53658536585366%;
    }

    .g3 {
        width: 13.6178861788618%;
    }

    .g4 {
        width: 18.6991869918699%;
    }

    .g5 {
        width: 23.780487804878%;
    }

    .g6 {
        width: 28.8617886178862%;
    }

    .g7 {
        width: 33.9430894308943%;
    }

    .g8 {
        width: 39.0243902439024%;
    }

    .g9 {
        width: 44.1056910569106%;
    }

    .g10 {
        width: 49.1869918699187%;
    }

    .g11 {
        width: 54.2682926829268%;
    }

    .g12 {
        width: 59.349593495935%;
    }

    .g13 {
        width: 64.4308943089431%;
    }

    .g14 {
        width: 69.5121951219512%;
    }

    .g15 {
        width: 74.5934959349593%;
    }

    .g16 {
        width: 79.6747967479675%;
    }

    .g17 {
        width: 84.7560975609756%;
    }

    .g18 {
        width: 89.8373983739837%;
    }

    .g19 {
        width: 94.9186991869919%;
    }

    /* columns - 2nd level */
    .g2 > .g1 {
        margin-right: 19.047619047619%;
    }

    .g2 > .g1 {
        width: 40.4761904761905%;
    }

    .g3 > .g1, .g3 > .g2 {
        margin-right: 11.9402985074627%;
    }

    .g3 > .g1 {
        width: 25.3731343283582%;
    }

    .g3 > .g2 {
        width: 62.6865671641791%;
    }

    .g4 > .g1, .g4 > .g2, .g4 > .g3 {
        margin-right: 8.69565217391304%;
    }

    .g4 > .g1 {
        width: 18.4782608695652%;
    }

    .g4 > .g2 {
        width: 45.6521739130435%;
    }

    .g4 > .g3 {
        width: 72.8260869565217%;
    }

    .g5 > .g1, .g5 > .g2, .g5 > .g3, .g5 > .g4 {
        margin-right: 6.83760683760684%;
    }

    .g5 > .g1 {
        width: 14.5299145299145%;
    }

    .g5 > .g2 {
        width: 35.8974358974359%;
    }

    .g5 > .g3 {
        width: 57.2649572649573%;
    }

    .g5 > .g4 {
        width: 78.6324786324786%;
    }

    .g6 > .g1, .g6 > .g2, .g6 > .g3, .g6 > .g4, .g6 > .g5 {
        margin-right: 5.63380281690141%;
    }

    .g6 > .g1 {
        width: 11.9718309859155%;
    }

    .g6 > .g2 {
        width: 29.5774647887324%;
    }

    .g6 > .g3 {
        width: 47.1830985915493%;
    }

    .g6 > .g4 {
        width: 64.7887323943662%;
    }

    .g6 > .g5 {
        width: 82.3943661971831%;
    }

    .g7 > .g1, .g7 > .g2, .g7 > .g3, .g7 > .g4, .g7 > .g5, .g7 > .g6 {
        margin-right: 4.79041916167665%;
    }

    .g7 > .g1 {
        width: 10.1796407185629%;
    }

    .g7 > .g2 {
        width: 25.1497005988024%;
    }

    .g7 > .g3 {
        width: 40.1197604790419%;
    }

    .g7 > .g4 {
        width: 55.0898203592814%;
    }

    .g7 > .g5 {
        width: 70.059880239521%;
    }

    .g7 > .g6 {
        width: 85.0299401197605%;
    }

    .g8 > .g1, .g8 > .g2, .g8 > .g3, .g8 > .g4, .g8 > .g5, .g8 > .g6, .g8 > .g7 {
        margin-right: 4.16666666666667%;
    }

    .g8 > .g1 {
        width: 8.85416666666667%;
    }

    .g8 > .g2 {
        width: 21.875%;
    }

    .g8 > .g3 {
        width: 34.8958333333333%;
    }

    .g8 > .g4 {
        width: 47.9166666666667%;
    }

    .g8 > .g5 {
        width: 60.9375%;
    }

    .g8 > .g6 {
        width: 73.9583333333333%;
    }

    .g8 > .g7 {
        width: 86.9791666666667%;
    }

    .g9 > .g1, .g9 > .g2, .g9 > .g3, .g9 > .g4, .g9 > .g5, .g9 > .g6, .g9 > .g7,
    .g9 > .g8 {
        margin-right: 3.68663594470046%;
    }

    .g9 > .g1 {
        width: 7.83410138248848%;
    }

    .g9 > .g2 {
        width: 19.3548387096774%;
    }

    .g9 > .g3 {
        width: 30.8755760368664%;
    }

    .g9 > .g4 {
        width: 42.3963133640553%;
    }

    .g9 > .g5 {
        width: 53.9170506912442%;
    }

    .g9 > .g6 {
        width: 65.4377880184332%;
    }

    .g9 > .g7 {
        width: 76.9585253456221%;
    }

    .g9 > .g8 {
        width: 88.4792626728111%;
    }

    .g10 > .g1, .g10 > .g2, .g10 > .g3, .g10 > .g4, .g10 > .g5, .g10 > .g6, .g10 > .g7,
    .g10 > .g8, .g10 > .g9 {
        margin-right: 3.30578512396694%;
    }

    .g10 > .g1 {
        width: 7.02479338842975%;
    }

    .g10 > .g2 {
        width: 17.3553719008264%;
    }

    .g10 > .g3 {
        width: 27.6859504132231%;
    }

    .g10 > .g4 {
        width: 38.0165289256198%;
    }

    .g10 > .g5 {
        width: 48.3471074380165%;
    }

    .g10 > .g6 {
        width: 58.6776859504132%;
    }

    .g10 > .g7 {
        width: 69.0082644628099%;
    }

    .g10 > .g8 {
        width: 79.3388429752066%;
    }

    .g10 > .g9 {
        width: 89.6694214876033%;
    }

    .g11 > .g1, .g11 > .g2, .g11 > .g3, .g11 > .g4, .g11 > .g5, .g11 > .g6, .g11 > .g7,
    .g11 > .g8, .g11 > .g9, .g11 > .g10 {
        margin-right: 2.99625468164794%;
    }

    .g11 > .g1 {
        width: 6.36704119850187%;
    }

    .g11 > .g2 {
        width: 15.7303370786517%;
    }

    .g11 > .g3 {
        width: 25.0936329588015%;
    }

    .g11 > .g4 {
        width: 34.4569288389513%;
    }

    .g11 > .g5 {
        width: 43.8202247191011%;
    }

    .g11 > .g6 {
        width: 53.1835205992509%;
    }

    .g11 > .g7 {
        width: 62.5468164794007%;
    }

    .g11 > .g8 {
        width: 71.9101123595506%;
    }

    .g11 > .g9 {
        width: 81.2734082397004%;
    }

    .g11 > .g10 {
        width: 90.6367041198502%;
    }

    .g12 > .g1, .g12 > .g2, .g12 > .g3, .g12 > .g4, .g12 > .g5, .g12 > .g6, .g12 > .g7,
    .g12 > .g8, .g12 > .g9, .g12 > .g10, .g12 > .g11 {
        margin-right: 2.73972602739726%;
    }

    .g12 > .g1 {
        width: 5.82191780821918%;
    }

    .g12 > .g2 {
        width: 14.3835616438356%;
    }

    .g12 > .g3 {
        width: 22.9452054794521%;
    }

    .g12 > .g4 {
        width: 31.5068493150685%;
    }

    .g12 > .g5 {
        width: 40.0684931506849%;
    }

    .g12 > .g6 {
        width: 48.6301369863014%;
    }

    .g12 > .g7 {
        width: 57.1917808219178%;
    }

    .g12 > .g8 {
        width: 65.7534246575342%;
    }

    .g12 > .g9 {
        width: 74.3150684931507%;
    }

    .g12 > .g10 {
        width: 82.8767123287671%;
    }

    .g12 > .g11 {
        width: 91.4383561643836%;
    }

    .g13 > .g1, .g13 > .g2, .g13 > .g3, .g13 > .g4, .g13 > .g5, .g13 > .g6, .g13 > .g7,
    .g13 > .g8, .g13 > .g9, .g13 > .g10, .g13 > .g11, .g13 > .g12 {
        margin-right: 2.52365930599369%;
    }

    .g13 > .g1 {
        width: 5.36277602523659%;
    }

    .g13 > .g2 {
        width: 13.2492113564669%;
    }

    .g13 > .g3 {
        width: 21.1356466876972%;
    }

    .g13 > .g4 {
        width: 29.0220820189274%;
    }

    .g13 > .g5 {
        width: 36.9085173501577%;
    }

    .g13 > .g6 {
        width: 44.794952681388%;
    }

    .g13 > .g7 {
        width: 52.6813880126183%;
    }

    .g13 > .g8 {
        width: 60.5678233438486%;
    }

    .g13 > .g9 {
        width: 68.4542586750789%;
    }

    .g13 > .g10 {
        width: 76.3406940063091%;
    }

    .g13 > .g11 {
        width: 84.2271293375394%;
    }

    .g13 > .g12 {
        width: 92.1135646687697%;
    }

    .g14 > .g1, .g14 > .g2, .g14 > .g3, .g14 > .g4, .g14 > .g5, .g14 > .g6, .g14 > .g7,
    .g14 > .g8, .g14 > .g9, .g14 > .g10, .g14 > .g11, .g14 > .g12, .g14 > .g13 {
        margin-right: 2.33918128654971%;
    }

    .g14 > .g1 {
        width: 4.97076023391813%;
    }

    .g14 > .g2 {
        width: 12.280701754386%;
    }

    .g14 > .g3 {
        width: 19.5906432748538%;
    }

    .g14 > .g4 {
        width: 26.9005847953216%;
    }

    .g14 > .g5 {
        width: 34.2105263157895%;
    }

    .g14 > .g6 {
        width: 41.5204678362573%;
    }

    .g14 > .g7 {
        width: 48.8304093567251%;
    }

    .g14 > .g8 {
        width: 56.140350877193%;
    }

    .g14 > .g9 {
        width: 63.4502923976608%;
    }

    .g14 > .g10 {
        width: 70.7602339181287%;
    }

    .g14 > .g11 {
        width: 78.0701754385965%;
    }

    .g14 > .g12 {
        width: 85.3801169590643%;
    }

    .g14 > .g13 {
        width: 92.6900584795322%;
    }

    .g15 > .g1, .g15 > .g2, .g15 > .g3, .g15 > .g4, .g15 > .g5, .g15 > .g6, .g15 > .g7,
    .g15 > .g8, .g15 > .g9, .g15 > .g10, .g15 > .g11, .g15 > .g12, .g15 > .g13,
    .g15 > .g14 {
        margin-right: 2.17983651226158%;
    }

    .g15 > .g1 {
        width: 4.63215258855586%;
    }

    .g15 > .g2 {
        width: 11.4441416893733%;
    }

    .g15 > .g3 {
        width: 18.2561307901907%;
    }

    .g15 > .g4 {
        width: 25.0681198910082%;
    }

    .g15 > .g5 {
        width: 31.8801089918256%;
    }

    .g15 > .g6 {
        width: 38.6920980926431%;
    }

    .g15 > .g7 {
        width: 45.5040871934605%;
    }

    .g15 > .g8 {
        width: 52.3160762942779%;
    }

    .g15 > .g9 {
        width: 59.1280653950954%;
    }

    .g15 > .g10 {
        width: 65.9400544959128%;
    }

    .g15 > .g11 {
        width: 72.7520435967302%;
    }

    .g15 > .g12 {
        width: 79.5640326975477%;
    }

    .g15 > .g13 {
        width: 86.3760217983651%;
    }

    .g15 > .g14 {
        width: 93.1880108991826%;
    }

    .g16 > .g1, .g16 > .g2, .g16 > .g3, .g16 > .g4, .g16 > .g5, .g16 > .g6, .g16 > .g7,
    .g16 > .g8, .g16 > .g9, .g16 > .g10, .g16 > .g11, .g16 > .g12, .g16 > .g13,
    .g16 > .g14, .g16 > .g15 {
        margin-right: 2.04081632653061%;
    }

    .g16 > .g1 {
        width: 4.33673469387755%;
    }

    .g16 > .g2 {
        width: 10.7142857142857%;
    }

    .g16 > .g3 {
        width: 17.0918367346939%;
    }

    .g16 > .g4 {
        width: 23.469387755102%;
    }

    .g16 > .g5 {
        width: 29.8469387755102%;
    }

    .g16 > .g6 {
        width: 36.2244897959184%;
    }

    .g16 > .g7 {
        width: 42.6020408163265%;
    }

    .g16 > .g8 {
        width: 48.9795918367347%;
    }

    .g16 > .g9 {
        width: 55.3571428571429%;
    }

    .g16 > .g10 {
        width: 61.734693877551%;
    }

    .g16 > .g11 {
        width: 68.1122448979592%;
    }

    .g16 > .g12 {
        width: 74.4897959183673%;
    }

    .g16 > .g13 {
        width: 80.8673469387755%;
    }

    .g16 > .g14 {
        width: 87.2448979591837%;
    }

    .g16 > .g15 {
        width: 93.6224489795918%;
    }

    .g17 > .g1, .g17 > .g2, .g17 > .g3, .g17 > .g4, .g17 > .g5, .g17 > .g6, .g17 > .g7,
    .g17 > .g8, .g17 > .g9, .g17 > .g10, .g17 > .g11, .g17 > .g12, .g17 > .g13,
    .g17 > .g14, .g17 > .g15, .g17 > .g16 {
        margin-right: 1.91846522781775%;
    }

    .g17 > .g1 {
        width: 4.07673860911271%;
    }

    .g17 > .g2 {
        width: 10.0719424460432%;
    }

    .g17 > .g3 {
        width: 16.0671462829736%;
    }

    .g17 > .g4 {
        width: 22.0623501199041%;
    }

    .g17 > .g5 {
        width: 28.0575539568345%;
    }

    .g17 > .g6 {
        width: 34.052757793765%;
    }

    .g17 > .g7 {
        width: 40.0479616306954%;
    }

    .g17 > .g8 {
        width: 46.0431654676259%;
    }

    .g17 > .g9 {
        width: 52.0383693045564%;
    }

    .g17 > .g10 {
        width: 58.0335731414868%;
    }

    .g17 > .g11 {
        width: 64.0287769784173%;
    }

    .g17 > .g12 {
        width: 70.0239808153477%;
    }

    .g17 > .g13 {
        width: 76.0191846522782%;
    }

    .g17 > .g14 {
        width: 82.0143884892086%;
    }

    .g17 > .g15 {
        width: 88.0095923261391%;
    }

    .g17 > .g16 {
        width: 94.0047961630695%;
    }

    .g18 > .g1, .g18 > .g2, .g18 > .g3, .g18 > .g4, .g18 > .g5, .g18 > .g6, .g18 > .g7,
    .g18 > .g8, .g18 > .g9, .g18 > .g10, .g18 > .g11, .g18 > .g12, .g18 > .g13,
    .g18 > .g14, .g18 > .g15, .g18 > .g16, .g18 > .g17 {
        margin-right: 1.80995475113122%;
    }

    .g18 > .g1 {
        width: 3.84615384615385%;
    }

    .g18 > .g2 {
        width: 9.50226244343891%;
    }

    .g18 > .g3 {
        width: 15.158371040724%;
    }

    .g18 > .g4 {
        width: 20.8144796380091%;
    }

    .g18 > .g5 {
        width: 26.4705882352941%;
    }

    .g18 > .g6 {
        width: 32.1266968325792%;
    }

    .g18 > .g7 {
        width: 37.7828054298643%;
    }

    .g18 > .g8 {
        width: 43.4389140271493%;
    }

    .g18 > .g9 {
        width: 49.0950226244344%;
    }

    .g18 > .g10 {
        width: 54.7511312217195%;
    }

    .g18 > .g11 {
        width: 60.4072398190045%;
    }

    .g18 > .g12 {
        width: 66.0633484162896%;
    }

    .g18 > .g13 {
        width: 71.7194570135747%;
    }

    .g18 > .g14 {
        width: 77.3755656108597%;
    }

    .g18 > .g15 {
        width: 83.0316742081448%;
    }

    .g18 > .g16 {
        width: 88.6877828054299%;
    }

    .g18 > .g17 {
        width: 94.3438914027149%;
    }

    .g19 > .g1, .g19 > .g2, .g19 > .g3, .g19 > .g4, .g19 > .g5, .g19 > .g6, .g19 > .g7,
    .g19 > .g8, .g19 > .g9, .g19 > .g10, .g19 > .g11, .g19 > .g12, .g19 > .g13,
    .g19 > .g14, .g19 > .g15, .g19 > .g16, .g19 > .g17, .g19 > .g18 {
        margin-right: 1.71306209850107%;
    }

    .g19 > .g1 {
        width: 3.64025695931478%;
    }

    .g19 > .g2 {
        width: 8.99357601713062%;
    }

    .g19 > .g3 {
        width: 14.3468950749465%;
    }

    .g19 > .g4 {
        width: 19.7002141327623%;
    }

    .g19 > .g5 {
        width: 25.0535331905782%;
    }

    .g19 > .g6 {
        width: 30.406852248394%;
    }

    .g19 > .g7 {
        width: 35.7601713062098%;
    }

    .g19 > .g8 {
        width: 41.1134903640257%;
    }

    .g19 > .g9 {
        width: 46.4668094218415%;
    }

    .g19 > .g10 {
        width: 51.8201284796574%;
    }

    .g19 > .g11 {
        width: 57.1734475374732%;
    }

    .g19 > .g12 {
        width: 62.5267665952891%;
    }

    .g19 > .g13 {
        width: 67.8800856531049%;
    }

    .g19 > .g14 {
        width: 73.2334047109208%;
    }

    .g19 > .g15 {
        width: 78.5867237687366%;
    }

    .g19 > .g16 {
        width: 83.9400428265525%;
    }

    .g19 > .g17 {
        width: 89.2933618843683%;
    }

    .g19 > .g18 {
        width: 94.6466809421842%;
    }

    .g20 > .g1, .g20 > .g2, .g20 > .g3, .g20 > .g4, .g20 > .g5, .g20 > .g6, .g20 > .g7,
    .g20 > .g8, .g20 > .g9, .g20 > .g10, .g20 > .g11, .g20 > .g12, .g20 > .g13,
    .g20 > .g14, .g20 > .g15, .g20 > .g16, .g20 > .g17, .g20 > .g18, .g20 > .g19 {
        margin-right: 1.6260162601626%;
    }

    .g20 > .g1 {
        width: 3.45528455284553%;
    }

    .g20 > .g2 {
        width: 8.53658536585366%;
    }

    .g20 > .g3 {
        width: 13.6178861788618%;
    }

    .g20 > .g4 {
        width: 18.6991869918699%;
    }

    .g20 > .g5 {
        width: 23.780487804878%;
    }

    .g20 > .g6 {
        width: 28.8617886178862%;
    }

    .g20 > .g7 {
        width: 33.9430894308943%;
    }

    .g20 > .g8 {
        width: 39.0243902439024%;
    }

    .g20 > .g9 {
        width: 44.1056910569106%;
    }

    .g20 > .g10 {
        width: 49.1869918699187%;
    }

    .g20 > .g11 {
        width: 54.2682926829268%;
    }

    .g20 > .g12 {
        width: 59.349593495935%;
    }

    .g20 > .g13 {
        width: 64.4308943089431%;
    }

    .g20 > .g14 {
        width: 69.5121951219512%;
    }

    .g20 > .g15 {
        width: 74.5934959349593%;
    }

    .g20 > .g16 {
        width: 79.6747967479675%;
    }

    .g20 > .g17 {
        width: 84.7560975609756%;
    }

    .g20 > .g18 {
        width: 89.8373983739837%;
    }

    .g20 > .g19 {
        width: 94.9186991869919%;
    }

    /* columns - halves definition */
    .g-half {
        width: 49.187%;
        margin-right: 1.626%;
    }

    .g19 .g-half {
        width: 49.143%;
        margin-right: 1.713%;
    }

    .g18 .g-half {
        width: 49.095%;
        margin-right: 1.809%;
    }

    .g17 .g-half {
        width: 49.022%;
        margin-right: 1.956%;
    }

    .g16 .g-half {
        width: 48.959%;
        margin-right: 2.081%;
    }

    .g15 .g-half {
        width: 48.888%;
        margin-right: 2.223%;
    }

    .g14 .g-half {
        width: 48.807%;
        margin-right: 2.385%;
    }

    .g13 .g-half {
        width: 48.713%;
        margin-right: 2.573%;
    }

    .g12 .g-half {
        width: 48.603%;
        margin-right: 2.793%;
    }

    .g11 .g-half {
        width: 48.472%;
        margin-right: 3.055%;
    }

    .g10 .g-half {
        width: 48.315%;
        margin-right: 3.370%;
    }

    .g9 .g-half {
        width: 48.120%;
        margin-right: 3.759%;
    }

    .g8 .g-half {
        width: 47.875%;
        margin-right: 4.249%;
    }

    .g7 .g-half {
        width: 47.557%;
        margin-right: 4.885%;
    }

    .g6 .g-half {
        width: 47.127%;
        margin-right: 5.745%;
    }

    .g5 .g-half {
        width: 46.514%;
        margin-right: 6.972%;
    }

    .g4 .g-half {
        width: 45.652%;
        margin-right: 8.696%;
    }

    .g3 .g-half {
        width: 44.029%;
        margin-right: 11.941%;
    }

    .g2 .g-half {
        width: 40.475%;
        margin-right: 19.050%;
    }

    .g1 .g-half {
        width: 26.465%;
        margin-right: 47.069%;
    }

    /* columns - thirds definition */
    .g-third {
        width: 32.249%;
        margin-right: 1.626%;
    }

    .g19 .g-third {
        width: 32.191%;
        margin-right: 1.713%;
    }

    .g18 .g-third {
        width: 32.127%;
        margin-right: 1.809%;
    }

    .g17 .g-third {
        width: 32.029%;
        margin-right: 1.956%;
    }

    .g16 .g-third {
        width: 31.945%;
        margin-right: 2.081%;
    }

    .g15 .g-third {
        width: 31.851%;
        margin-right: 2.223%;
    }

    .g14 .g-third {
        width: 31.743%;
        margin-right: 2.385%;
    }

    .g13 .g-third {
        width: 31.618%;
        margin-right: 2.573%;
    }

    .g12 .g-third {
        width: 31.471%;
        margin-right: 2.793%;
    }

    .g11 .g-third {
        width: 31.296%;
        margin-right: 3.055%;
    }

    .g10 .g-third {
        width: 31.086%;
        margin-right: 3.370%;
    }

    .g9 .g-third {
        width: 30.827%;
        margin-right: 3.759%;
    }

    .g8 .g-third {
        width: 30.500%;
        margin-right: 4.249%;
    }

    .g7 .g-third {
        width: 30.076%;
        margin-right: 4.885%;
    }

    .g6 .g-third {
        width: 29.503%;
        margin-right: 5.745%;
    }

    .g5 .g-third {
        width: 28.685%;
        margin-right: 6.972%;
    }

    .g4 .g-third {
        width: 27.536%;
        margin-right: 8.696%;
    }

    .g3 .g-third {
        width: 25.372%;
        margin-right: 11.941%;
    }

    .g2 .g-third {
        width: 20.633%;
        margin-right: 19.050%;
    }

    .g1 .g-third {
        width: 1.9539%;
        margin-right: 47.069%;
    }
}

/* BP5 */

@media only screen and (min-width: 1025px) {

    .w-grid,
    .w-grid-full {
        min-width: 984px;
    }
}